.hero-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .hero-headline{
    display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .hero-name{
    font-size: 36px;
    font-weight: bold;
  }
  
  .hero-title{
    font-size: 24px;
    font-weight: bolder;
  }
  
  .headshot {
    margin-left: 0;
    width: auto;
    border-radius: 500px;  
    box-shadow: 5px 5px 5px #9b766e;
  }
  @media screen and (min-width: 745px) {
    .headshot {
      margin-left: 50px;
      height: 500px;
    }
  }
  @media screen and (min-width: 745px) {
    .hero-container{
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .hero-headline{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .headshot {
      margin-left: 50px;
      /* width: auto;
      height: 500px;
      border-radius: 500px;  
      box-shadow: 5px 5px 5px #9b766e; */
    }
  }
.app{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #9b766e;
  font-family: "Monaco", monospace;
  background-color: #fdf1f0;
}

.app-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px 200px;
  flex-wrap: wrap;
}

.copy{
  display: flex;
  justify-content: center;
}

.card-content{
  border: solid 1px #9b766e52;
  box-shadow: 20px 15px 15px #9b766e;
  margin: 40px 20px;
  padding: 20px;
}

.about-description{
  margin: 40px 50px 50px 20px;
  font-size: 18px;
  text-align: justify;
  line-height: 1.75;
}

.projects-section{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-evenly;
    margin: 40px;
}

@media (max-width: 1269px) {
  .projects-section{
    display: grid;
    grid-template-columns: auto auto;
    gap: 25px;
    justify-items: center;
    justify-content: center;
  }
}

@media (max-width: 1090px) {
  .projects-section{
    display: grid;
    grid-template-columns: auto;
    gap: 25px;
    justify-items: center;
    justify-content: center;
  }
}

.projects-section_card{
  width: 100%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.projects-section_actions{
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.projects-section_image{
  width: 100%;
  height: 200px;
  /* height: 20vh; */
}
.projects-page_link{
  color: #7d5f58;
  font-size: 18px;
  text-decoration: none;
}
.projects-page_link:hover{
  color: #9b766e;
  font-weight: bold;
}

.contact-section{
  margin: 10px 20px 40px 10px;
}

.contact-section_link{
  margin: 20px;
  font-size: 30px;
  color: #7d5f58;
}

@media screen and (min-width: 745px) {
  .app{
    display: block;
    justify-content: center;
    color: #9b766e;
    font-family: "Monaco", monospace;
    background-color: #fdf1f0;
    padding: 40px;
  }
}
/* .ant-card-actions {
  background: #fff;
  border-top: 1px solid #f0f0f0;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  width: 100%;
} */